import React from 'react'
import styled from '@emotion/styled'
import random from 'lodash/random'
import { Link, graphql } from 'gatsby'

import { Header, Layout, Scramble } from '../../components'

const Title = styled.h3`
  margin-bottom: 1px;
  font-weight: 500;
`

const Date = styled.span`
  display: block;
  color: #bbb;
  font-size: 1rem;
`

const titles = [
  'Beyond Love and Grief.',
  'Forever Within',
  'From One Thing, Know Ten Thousand',
  'Life as Art',
  'Living in Discrete Intervals',
  'Short. Brief. Musings.',
  'To Know Ten Thousand Things, Know One Well',
]

export default ({ data }) => (
  <Layout>
    <Header>
      <Scramble>{titles[random(titles.length - 1)]}</Scramble>
    </Header>
    <hr />
    <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <div key={node.id}>
        <Title>
          <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          <Date>&nbsp;{node.frontmatter.date}</Date>
        </Title>
        <p>{node.excerpt}</p>
      </div>
    ))}
  </Layout>
)

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
